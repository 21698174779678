header {
    background-color: white;
    position: sticky;
    top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

* {
    scroll-margin-top: 64px;
}

@media (min-width: 992px) {
    * {
        scroll-margin-top: 140px;
    }
}