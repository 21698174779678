//
// Bootstrap list group overrides
//


/*List style*/
.list-group .list-group-item { 
  background: transparent;
 }
.list-group .list-group-item i{
  margin-right: 5px;
}
/*list group borderless*/
.list-group-borderless .list-group-item {
    border: none;
    color: $gray-600;
    padding: 0.425rem 0;
}
.list-group-borderless a.list-group-item:hover {
    background-color: transparent;
    color: $primary;
}

/*list group inline*/
.list-group-inline li  { 
  border: none;
  display: inline-block;
  color: $gray-600;
  padding: 0.425rem 0;
 }
.list-group-inline .list-group-item  { 
  border: none;
  display: inline-block;
  color: $gray-600;
  padding: 0.425rem 0;
  margin-right: 30px;
  width: auto;
 }
.list-group-item-action:hover, 
.list-group-item-action:focus{
  background: transparent;
  color: $primary;
}

// list group with number
.list-group-number .list-group-item-action{
  font-weight: $font-weight-normal;
}
.list-group-number .list-group-item-action:last-child {
  margin-right: 0;
}
.list-group-number span{
    background: $gray-200;
    color: $gray-600;
    font-weight: $font-weight-bold; 
    height: 22px;
    width: 22px;
    line-height: 22px;
    text-align: center;
    display: block;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
    margin-top: 1px;
    font-size: 11px;
}

.list-group-icon-primary-bg{
  .list-group-item{
    display: flex;
  }
  i{
    background: lighten($primary, 40%);
    color: $primary;
    font-weight: $font-weight-bold; 
    height: 22px;
    width: 22px;
    line-height: 23px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px !important;
    margin-top: 1px;
    font-size: 11px;
    flex-shrink: 0;
    justify-content: center;
  }
}